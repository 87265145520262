define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data,depth1) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n        ";
  stack1 = (helper = helpers.if_gt || (depth0 && depth0.if_gt),options={hash:{
    'compare': ("0")
  },inverse:self.noop,fn:self.programWithDepth(2, program2, data, depth1),data:data},helper ? helper.call(depth0, (depth0 && depth0.count), options) : helperMissing.call(depth0, "if_gt", (depth0 && depth0.count), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  return buffer;
  }
function program2(depth0,data,depth2) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n            <li class=\"l-unit\">\n    			<span class=\"spnFacetBox\">\n                    ";
  stack1 = (helper = helpers.if_match || (depth2 && depth2.if_match),options={hash:{
    'compare': (true)
  },inverse:self.noop,fn:self.program(3, program3, data),data:data},helper ? helper.call(depth0, ((stack1 = (depth2 && depth2.facetSets)),stack1 == null || stack1 === false ? stack1 : stack1.isLink), options) : helperMissing.call(depth0, "if_match", ((stack1 = (depth2 && depth2.facetSets)),stack1 == null || stack1 === false ? stack1 : stack1.isLink), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                    ";
  stack1 = (helper = helpers.if_not_match || (depth2 && depth2.if_not_match),options={hash:{
    'compare': (true)
  },inverse:self.noop,fn:self.program(5, program5, data),data:data},helper ? helper.call(depth0, ((stack1 = (depth2 && depth2.facetSets)),stack1 == null || stack1 === false ? stack1 : stack1.isLink), options) : helperMissing.call(depth0, "if_not_match", ((stack1 = (depth2 && depth2.facetSets)),stack1 == null || stack1 === false ? stack1 : stack1.isLink), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    			</span>\n            </li>\n        ";
  return buffer;
  }
function program3(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n                        <a href=\"";
  if (helper = helpers.searchQuery) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.searchQuery); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" title=\"";
  if (helper = helpers.value) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.value); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n                            ";
  if (helper = helpers.displayName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.displayName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " (";
  if (helper = helpers.count) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.count); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += ")\n                        </a>\n                    ";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                        <label>\n                            <input type=\"checkbox\" class=\"facetBox\" data-facetcontrol=\"facetBox-";
  if (helper = helpers.fieldName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.fieldName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ":";
  if (helper = helpers.value) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.value); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" data-facetsetowner=\"savings\" value=\"";
  if (helper = helpers.fieldName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.fieldName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ":";
  if (helper = helpers.value) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.value); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.selected), {hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "/>\n                            ";
  stack1 = (helper = helpers.if_match || (depth0 && depth0.if_match),options={hash:{
    'compare': ("averageReviewScore_auto_content")
  },inverse:self.noop,fn:self.program(8, program8, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.fieldName), options) : helperMissing.call(depth0, "if_match", (depth0 && depth0.fieldName), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                            ";
  stack1 = (helper = helpers.if_not_match || (depth0 && depth0.if_not_match),options={hash:{
    'compare': ("averageReviewScore_auto_content")
  },inverse:self.noop,fn:self.program(10, program10, data),data:data},helper ? helper.call(depth0, (depth0 && depth0.fieldName), options) : helperMissing.call(depth0, "if_not_match", (depth0 && depth0.fieldName), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                        </label>\n                    ";
  return buffer;
  }
function program6(depth0,data) {
  
  
  return " checked ";
  }

function program8(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                                <div class=\"rating-holder\">\n                                    <div class=\"rating\"></div>\n                                    <span class=\"rating-stars\" style=\"width:";
  stack1 = (helper = helpers.divide_round || (depth0 && depth0.divide_round),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.displayName), options) : helperMissing.call(depth0, "divide_round", (depth0 && depth0.displayName), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "%\">";
  if (helper = helpers.displayName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.displayName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n                                </div>\n                            ";
  return buffer;
  }

function program10(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n                                ";
  if (helper = helpers.displayName) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.displayName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                            ";
  return buffer;
  }

  buffer += "    ";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 && depth0.facetSets)),stack1 == null || stack1 === false ? stack1 : stack1.facets), {hash:{},inverse:self.noop,fn:self.programWithDepth(1, program1, data, depth0),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n";
  return buffer;
  }); });